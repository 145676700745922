// extracted by mini-css-extract-plugin
export var root = "PlasmicPlan-module--root--27EcQ";
export var box___3Dl4J = "PlasmicPlan-module--box___3Dl4J--1w7yo";
export var box___6QuKh = "PlasmicPlan-module--box___6QuKh--3jOCi";
export var slotName = "PlasmicPlan-module--slotName--1clZ5";
export var box__dG0B = "PlasmicPlan-module--box__dG0B--1pB1V";
export var box__tnsmA = "PlasmicPlan-module--box__tnsmA--vPX3Z";
export var bullet__tsyUi = "PlasmicPlan-module--bullet__tsyUi--Qebl8";
export var bullet__mKaim = "PlasmicPlan-module--bullet__mKaim--2lCcF";
export var bullet__wyanT = "PlasmicPlan-module--bullet__wyanT--3JX-5";
export var box__fI94I = "PlasmicPlan-module--box__fI94I--iLBXc";
export var svg__gqLKl = "PlasmicPlan-module--svg__gqLKl--3F3UE";