// extracted by mini-css-extract-plugin
export var root = "PlasmicPricing-module--root--rcns1";
export var box__rBGsD = "PlasmicPricing-module--box__rBGsD--1hR3U";
export var header = "PlasmicPricing-module--header--arUMc";
export var section__oNdGe = "PlasmicPricing-module--section__oNdGe--2qxY8";
export var box__fpH6 = "PlasmicPricing-module--box__fpH6--b6r5D";
export var plan__nr3Z = "PlasmicPricing-module--plan__nr3Z--185Wd";
export var box__r6PK = "PlasmicPricing-module--box__r6PK--3mTla";
export var box__kMnIu = "PlasmicPricing-module--box__kMnIu--3eL6u";
export var bullet__c4Gut = "PlasmicPricing-module--bullet__c4Gut--2OCrK";
export var bullet__ddz5A = "PlasmicPricing-module--bullet__ddz5A--3dt49";
export var bullet__nSoOb = "PlasmicPricing-module--bullet__nSoOb--2J3K3";
export var plan__ty6Nr = "PlasmicPricing-module--plan__ty6Nr--rnfJs";
export var box___7LkUx = "PlasmicPricing-module--box___7LkUx--9zc2g";
export var box___6CX9M = "PlasmicPricing-module--box___6CX9M--37AUd";
export var bullet__d8DXn = "PlasmicPricing-module--bullet__d8DXn--19w4T";
export var bullet__qgAg8 = "PlasmicPricing-module--bullet__qgAg8--C-fm2";
export var bullet__eooLk = "PlasmicPricing-module--bullet__eooLk--2WRfg";
export var bullet__kwd2Y = "PlasmicPricing-module--bullet__kwd2Y--36ffh";
export var plan__cfk8K = "PlasmicPricing-module--plan__cfk8K--3AznC";
export var box__dp4V8 = "PlasmicPricing-module--box__dp4V8--msLNL";
export var box__iVa2A = "PlasmicPricing-module--box__iVa2A--1ktUf";
export var bullet__sRbGn = "PlasmicPricing-module--bullet__sRbGn--2OAHE";
export var bullet__vvPlc = "PlasmicPricing-module--bullet__vvPlc--1uyMA";
export var bullet__ebMsr = "PlasmicPricing-module--bullet__ebMsr--1O8ub";
export var section__bo1Qe = "PlasmicPricing-module--section__bo1Qe--2FClP";
export var faq__tjpZt = "PlasmicPricing-module--faq__tjpZt--37q9X";
export var faq___5HA0I = "PlasmicPricing-module--faq___5HA0I--2YvyS";
export var faq__wbkNw = "PlasmicPricing-module--faq__wbkNw--LKsvG";
export var footer = "PlasmicPricing-module--footer--k54-O";